import React from 'react';
import { RecoilRoot } from 'recoil';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import MapManager from './containers/mapManager';
import ProjectTools from './components/projectTools';
import VolunteerRegistration from './components/volunteerRegistration';
import Snackbar from './components/snackbar';

const App = ({showNewProjectButton, showInfoDialog, showProjectPlanner, isVolunteerRegistration}) => {
  let showInfoDialogMapProp = showInfoDialog;
  let showNewProjectButtonMapProp = showNewProjectButton;
  if (showProjectPlanner) {
    // If show project planner, map should not show info dialog or new button
    showInfoDialogMapProp = false;
    showNewProjectButtonMapProp = false;
  }

  return (
  <RecoilRoot>
    <CssBaseline />
    <Snackbar />
      { isVolunteerRegistration && 
      <VolunteerRegistration />
      }
      { !isVolunteerRegistration &&
        <MapManager 
          showInfoDialog={showInfoDialogMapProp} 
          showNewProjectButton={showNewProjectButtonMapProp}
          showProjectPlanner={showProjectPlanner}
        />
    }
  </RecoilRoot>);
}

App.propTypes = {
  showNewProjectButton: PropTypes.bool,
  showInfoDialog: PropTypes.bool,
  showProjectPlanner: PropTypes.bool,
  isVolunteerRegistration: PropTypes.bool,
};
App.defaultProps = {
  showNewProjectButton: false,
  showInfoDialog: true,
  showProjectPlanner: false,
  isVolunteerRegistration: false,
};

export default App;

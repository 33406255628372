import React from 'react';
import { useSetRecoilState } from 'recoil';
import { orgSearchParamState } from '../searchFiltersTabs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FLAG_DETAILS as ORG_FLAG_DETAILS } from '../../constants/orgFlags';
import IconLabel from '../iconLabel';

const OrgSearchFilters = () => {
  const setOrgSearchParams = useSetRecoilState(orgSearchParamState);

  const orgSearchParamsHandleOnChangeCheckbox = (event) => {
    const currentValue = event.target.value;
    const isChecked = event.target.checked;
    setOrgSearchParams(orgSearchParams => ({...orgSearchParams, [currentValue]: isChecked}))
  };
  return (
    <Grid container rowSpacing={0} columnSpacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Organization Services</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          {Object.keys(ORG_FLAG_DETAILS).map((column) => (
            <FormControlLabel
              label={
              <IconLabel 
                key={column} 
                label={ORG_FLAG_DETAILS[column].label} 
                IconType={ORG_FLAG_DETAILS[column].IconType}
                iconProps={ORG_FLAG_DETAILS[column].iconStyle ? { style: ORG_FLAG_DETAILS[column].iconStyle } : {}}
              />}
              key={column}
              control={
                <Checkbox
                  value={ORG_FLAG_DETAILS[column].searchParam}
                  onChange={orgSearchParamsHandleOnChangeCheckbox}
                />
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};
export default OrgSearchFilters;

import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import messages from './messages';

const ProjectTools = () => {
  const toolGridItems = messages.toolTitles.map((title, index) => {
    return <ToolGridItem key={index} title={title} />;
  });
  return (
    <Grid container rowSpacing={4} columnSpacing={1} sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 10, paddingBottom: 10 }}>
      { toolGridItems }
    </Grid>
  );
};

const ToolGridItem = ({title}) => {
  return (
  <Grid item xs={12}>
    <Link><Typography align="center" variant="h4">{title}</Typography></Link>
  </Grid>
  );
}
export default ProjectTools;

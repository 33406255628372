import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AttributionIcon from '@mui/icons-material/Attribution';
import EmailIcon from '@mui/icons-material/Email';
import ToolTipIconButton from '../toolTipIconButton';
import ToolTipIconButtonCopyClipboard from '../toolTipIconButtonCopyClipboard';
import messages from './messages';
import { EMAIL } from './constants';
import { VOLUNTEER_REGISTRATION_PAGE } from '../../routes';

const TopBanner = ({ showNewProjectButton, handleShowNewProject, showVolunteerRegistration}) => {
    const navigate = useNavigate();

    return (
    <Box sx={{display:'block', position: 'relative', lineHeight:0}}>
        <Box sx={{position: 'relative'}}>
            <img src={`${process.env.PUBLIC_URL}/banner.jpg`} alt="pin" style={{maxWidth:'100%'}} />
        </Box>
        <Box sx={{position:'absolute', bottom:0, left:2}}>
            <ToolTipIconButton
                buttonStyles={{padding:0}}
                label={messages.logoAttributionLabel}
                IconType={AttributionIcon}
                iconProps={{fontSize:'small'}}
                onClickCallback={() =>
                window.open(messages.logoAttributionLink, '_blank')
                }
                color='black'
            />
        </Box>
        <Box sx={{position:'absolute', bottom:0, right:2}}>
            { showNewProjectButton && 
            <Button variant="text" onClick={() => handleShowNewProject()}>{messages.newProjectHeader}</Button>
            }
            <ToolTipIconButtonCopyClipboard
                label={EMAIL}
                IconType={EmailIcon}
                buttonStyles={{alignItems:'flex-end'}}
                iconProps={
                {style:{color:'mediumblue'}}
                }
            />
        </Box>
        { showVolunteerRegistration &&
        <Box sx={{position:'absolute', top:4, right:4}}>
            <Button variant="outlined" color="info" onClick={() => { navigate(VOLUNTEER_REGISTRATION_PAGE) }}><b>{messages.registerVolunteerButton}</b></Button>
        </Box>
        }
    </Box>
  );
};

TopBanner.propTypes = {
    showNewProjectButton: PropTypes.bool,
    handleShowNewProject: PropTypes.func,
    showVolunteerRegistration: PropTypes.bool,
};
TopBanner.defaultProps = {
    showNewProjectButton: false,
    showVolunteerRegistration: true,
    handleShowNewProject: () => {},
};

export default TopBanner;

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import CountryList from 'country-list';
import { createVolunteer } from '../../utils/createVolunteer';
import messages from './messages';
import { INITIAL_FORM_STATE } from './constants';
import AppBarHeader from '../appHeader';

const VolunteerRegistrationSuccess = () => {
  return (
    <>
    <Typography variant="h5" gutterBottom>{messages.submitSuccess}</Typography>
    <Button variant="contained" type="submit">
      {messages.registerAnother}
    </Button>
  </>
  );
};

const VolunterRegistrationForm = ({formData, handleFieldChange, isSubmitting, showFormErrors, submitError}) => {
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    if (countryOptions.length === 0) {
      const countryObject = CountryList.getCodeList();
      setCountryOptions(Object.keys(countryObject).map(
        (countryKey) => countryObject[countryKey])
      );
    }
  }, []);
  return (
    <>
    <Typography variant="body1" gutterBottom>{messages.instructions}</Typography>

  <div>
    <TextField 
      required
      error={showFormErrors && !formData['firstName'].valid}
      disabled={isSubmitting}
      label={messages.firstName}
      placeholder={messages.firstName} 
      onChange={(e) => {
        handleFieldChange(e.target.value, 'firstName', e.target.validity.valid);
      }}
      value={formData?.firstName.value} 
    />
    <TextField 
      disabled={isSubmitting}
      label={messages.middleName}
      placeholder={messages.middleName}
      onChange={(e) => {
        handleFieldChange(e.target.value, 'middleName', e.target.validity.valid);
      }}
      value={formData?.middleName.value}
    />
    <TextField 
      error={showFormErrors && !formData['lastName'].valid}
      disabled={isSubmitting}
      required
      label={messages.lastName}
      placeholder={messages.lastName} 
      onChange={(e) => {
        handleFieldChange(e.target.value, 'lastName', e.target.validity.valid);
      }}
      value={formData?.lastName.value}
    />
   </div>
   <div>
      <TextField
        error={showFormErrors && !formData['email'].valid}
        disabled={isSubmitting}
        required
        label={messages.email} 
        placeholder={messages.email} 
        onChange={(e) => {
          handleFieldChange(e.target.value, 'email', e.target.validity.valid);
        }}
        value={formData?.email.value}
        inputProps={{
          type: "email",
        }}
      />
      <div>
      <Autocomplete
        disabled={isSubmitting}
        multiple
        id="volunteerCountries"
        options={countryOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={messages.countries}
            error={showFormErrors && !formData['countries'].valid}
          />
        )}
        onChange={(e, value) => {
          handleFieldChange(value, 'countries', value.length > 0);
        }}
        value={formData?.countries.value}
      />
      <Typography gutterBottom sx={{ paddingLeft: 1}} variant='caption'>{messages.countriesAvailable}</Typography>

      </div>
      <div>
      <LoadingButton 
        disabled={isSubmitting}
        loading={isSubmitting} 
        variant="contained" 
        type="submit" 
        sx={{marginBottom:1, marginTop:2}}>
        {messages.submit}
      </LoadingButton>
      { submitError && 
      <div>
        <Typography variant="overline" color="red">{submitError}</Typography>
      </div>
    }
      </div> 
    </div>
    </>
  );
};

const VolunteerRegistration = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const handleFieldChange = (value, stateKey, isValid) => {
    setShowFormErrors(false);
    setSubmitError('');
    setFormData({...formData, [stateKey]: {value, valid:isValid}});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (showSubmitSuccess) {
      setShowSubmitSuccess(false);
      setSubmitError('');
      setIsSubmitting(false);
      setFormData(INITIAL_FORM_STATE);
    } else if (e.target.checkValidity()) {
      setIsSubmitting(true);
      const formValues = reduce(formData, (result, formValue, formKey) => {
        result = {...result, [formKey]: formValue.value};
        return result;
      }, {});
      createVolunteer(formValues)
        .then(() => {
          setShowSubmitSuccess(true);
          setSubmitError('');
        })
        .catch((error) => {
          let errorMsg = '';
          if (error.response.data && error.response.data.statusCode === 400 && !isEmpty(error.response.data.message)) {
            errorMsg = `${messages.submitErrorIntro} ${error.response.data.message}`;
          } else {
            errorMsg = `${messages.submitErrorIntro} ${messages.submitErrorTryAgain}`;
          }
          setSubmitError(errorMsg);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setShowFormErrors(true);
    }
  };

  
  return (
    <Stack spacing="0" sx={{width:'100%', marginTop:0}}>
      <AppBarHeader showVolunteerRegistration={false}>
        <Typography variant="h5">{messages.title}</Typography>
      </AppBarHeader>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, padding: 2 }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        { showSubmitSuccess && 
          <VolunteerRegistrationSuccess />
        }
        { !showSubmitSuccess && 
          <VolunterRegistrationForm 
            formData={formData}
            handleFieldChange={handleFieldChange}
            isSubmitting={isSubmitting}
            submitError={submitError}
            showFormErrors={showFormErrors}
          />
        }
    </Box>
   </Stack>
  );
};
export default VolunteerRegistration;

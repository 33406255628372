import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import { useRecoilState } from 'recoil';
import { getScientificMethodologies } from '../../utils/getScientificMethodologies';
import { getRestorationMethodologies } from '../../utils/getRestorationMethodologies';
import { getWorksiteCountries } from '../../utils/getWorksiteCountries';
import { getWorksiteRegions } from '../../utils/getWorksiteRegions';
import { getWorksites } from '../../utils/getWorksites';
import { searchFiltersOpen } from './searchState';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import MethodSearchFilters from '../methodSearchFilters';
import OrgSearchFilters from '../orgSearchFilters';
import LocationSearchFilters from '../locationSearchFilter';
import messages from './messages';

const getRestorationMethodsByType = (restorationMethods) => {
  const restMethodsByType = {};
  restorationMethods.forEach((restorationMethod) => {
    if (!isEmpty(restorationMethod.type)) {
      if (
        !has(restMethodsByType, restorationMethod.type) ||
        isEmpty(restMethodsByType[restorationMethod.technique])
      ) {
        restMethodsByType[restorationMethod.type] = [];
      }
      restMethodsByType[restorationMethod.type].push(restorationMethod);
    }
  });
  return restMethodsByType;
};

const getScientificMethodsByType = (scientificMethods) => {
  const scientificMethodsByType = {};
  scientificMethods.forEach((scientificMethod) => {
    if (!isEmpty(scientificMethod.type)) {
      if (!has(scientificMethodsByType, scientificMethod.type)) {
        scientificMethodsByType[scientificMethod.type] = [];
      }
      scientificMethodsByType[scientificMethod.type].push(scientificMethod);
    }
  });
  return scientificMethodsByType;
};

const TabPanel = (props) => {
  const { children, value, index } = props;
  return <>{value === index && <Box sx={{ p: 3 }}>{children}</Box>}</>;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const SearchFiltersTabs = () => {
  const [methodologies, setMethodologies] = useState({});
  const [worksites, setWorksites] = useState({});
  const [filtersOpen, setFiltersOpen] = useRecoilState(searchFiltersOpen);
  // const [methodSearchParams, setMethodSearchParms] = useState({});
  // const [orgSearchParams, setOrgSearchParms] = useState({});
  // const [locationSearchParams, setLocationSearchParams] = useState({});

  useEffect(() => {
      Promise.all([
        getScientificMethodologies(),
        getRestorationMethodologies(),
        getWorksites(),
        getWorksiteCountries(),
        getWorksiteRegions(),
      ]).then(([allScientificMethods, allRestorationMethods, allWorksites, allWorksiteCountries, allWorksiteRegions]) => {
        setMethodologies({
          allScientificMethodsByType: getScientificMethodsByType(allScientificMethods),
          allRestorationMethodsByType: getRestorationMethodsByType(allRestorationMethods),
          allScientificMethods,
          allRestorationMethods,
        });
        setWorksites({all: allWorksites, countries: allWorksiteCountries.filter(c=>c), regions: allWorksiteRegions.filter(r => r)});
      });
    }, []);

  if (isEmpty(methodologies) || isEmpty(worksites)) {
    // Do not show search controls if loading
    return null;
  }
  return (
    <Box sx={
      {width: '100%', color:'black'}}
    >
      <Box sx={
          { backgroundColor:'white',  borderTopLeftRadius: 8, 
          borderTopRightRadius: 8, width:'fit-content' }
        }
        >
          <Button
            size="small"
            onClick={() => {
              setFiltersOpen(!filtersOpen);
            }}                    
              variant="contained"
              color="info"  
          >
            <Typography variant="button">{messages.searchTab}</Typography>
          </Button>
        </Box>
        <Box sx={{position:'absolute'}}>
          <Grow in={filtersOpen} style={{ transformOrigin: '50% 0 0' }}>
            <Box
              bgcolor="white"
              sx={{
                width: '100%',
                right: 0,
                paddingTop: 2, 
                paddingBottom: 2,
              }}
            >
            <OrgSearchFilters />
            <MethodSearchFilters
              filterData={methodologies}
            />
            <LocationSearchFilters
              allWorksites={worksites.all}
              allWorksiteCountries={worksites.countries}
              allWorksiteRegions={worksites.regions}
            />
          </Box>
        </Grow>
      </Box>
  </Box>
  );
};

export default SearchFiltersTabs;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import NearMeIcon from '@mui/icons-material/NearMe';
import Typography from '@mui/material/Typography';
import messages from '../mapManager/messages';
import {getOrganizationIdsFromMethodPractices} from '../../utils/getOrganizations';
import { snackbarState } from '../../components/snackbar';
import { mapState } from '../../components/map';
import { methodPracticeParamState, searchFiltersOpen } from '../../components/searchFiltersTabs';
import IntroDialog from '../../components/introDialog';
import SearchLocationModal from '../../components/searchLocationModal';
import ProjectTools from '../../components/projectTools';

import isEmpty from 'lodash/isEmpty';

import Map from '../../components/map';
// import { getOrganizations } from '../../utils/getOrganizations';
import {
  getMethodologyPracticesByMethodology
  // getAllMethodologyPractice
} from '../../utils/getMethodologyPractices';
// import { getCoordinatesFromAddress } from '../../utils/geocoding';
import SearchFiltersTabs from '../../components/searchFiltersTabs';
import AppBarHeader from '../../components/appHeader';

const TRAINING_LINK = "https://reefhabilitation.org/training/";
const NEW_PROJECTS_LINK = "https://reefhabilitation.org/existing-projects/";

const MapManager = ({ showNewProjectButton, showIntroDialog, showProjectPlanner }) => {
  // const [getOrgsParams, setGetOrgParams] = useState({});
  const [showNewProjectLocation, setShowNewProjectLocation] = useState(false);
  //const [getMethodPracticesParams, setGetMethodPracticesParams] = useState(initMethodSearchParams);
  // const [orgMarkersLoaded, setOrgMarkersLoaded] = useState(true);
  const [methodPracticeMarkersLoaded, setMethodPracticeMarkersLoaded] = useState(false);
  // const [orgMarkers, setOrgMarkers] = useState([]);
  const [methodPracticesMarkers, setMethodPracticesMarkers] = useState([]);
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const [introDialogOpen, setIntroDialogOpen] = useState(false);
  const filtersOpen = useRecoilValue(searchFiltersOpen);
   
  const setSnackbarProps = useSetRecoilState(snackbarState);
  const { map } = useRecoilValue(mapState);
  const methodPracticeParams = useRecoilValue(methodPracticeParamState);

  const panToMyLocation = () => {
    setLoadingMyLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
          };
          map.setCenter(pos);
          map.setZoom(8);
          setLoadingMyLocation(false);
        },
        () => {
        setLoadingMyLocation(false);
        setSnackbarProps((prevState) => ({
          ...prevState,
          open: true,
          message: messages.cannotFindMyLocationError,
          alertSeverity: 'error',
          }))
        }
      );
    } else {
      setLoadingMyLocation(false);
      // Browser doesn't support Geolocation
      setSnackbarProps((prevState) => ({
          ...prevState,
          open: true,
          message: messages.cannotFindMyLocationError,
          alertSeverity: 'error',
      }))      
    }
  }

  const fetchMethodPractices = async () => {
    const methodPractices = await getMethodologyPracticesByMethodology(methodPracticeParams);
    return methodPractices.data;
  };

  useEffect(() => {
    if (showIntroDialog) {
      setIntroDialogOpen(true);
    }
  }, [showIntroDialog])

  useEffect(() => {
    if (!isEmpty(methodPracticeParams)) {
      if (!methodPracticeMarkersLoaded) {
        fetchMethodPractices().then((methodPracticeMarkers) => {
          setMethodPracticesMarkers(methodPracticeMarkers);
          setMethodPracticeMarkersLoaded(true);
        });
      }
    } else {
      setMethodPracticesMarkers([]);
    }
  }, [methodPracticeMarkersLoaded]);
  
  useEffect(() => {
    setMethodPracticeMarkersLoaded(false);
  }, [methodPracticeParams]);

  const allOrgIds = getOrganizationIdsFromMethodPractices(methodPracticesMarkers);
  const windowInnerWidth = window.innerWidth;
  return (
    <>
      <Box sx={{ flex: 1, padding:0, alignItems: 'center', flexDirection: 'column', height: '100vh'}}>
        <AppBarHeader showNewProjectButton={showNewProjectButton} handleShowNewProject={() => setShowNewProjectLocation(true)}>
          { !showProjectPlanner &&
          <Box sx={{marginLeft:'auto', paddingRight: 2}}>                  
            <Stack direction="row" spacing={2} sx={{height:"100%", alignItems:"center"}}>
              <Box display="flex" sx={
                {opacity: 0.9, zIndex: filtersOpen ? 'modal' : 'auto'}}
              >
                <SearchFiltersTabs />
              </Box>
              { map &&
              <LoadingButton
                size="small"
                onClick={() => panToMyLocation()}
                endIcon={<NearMeIcon />}
                loading={loadingMyLocation}
                loadingPosition="end"
                variant="contained"
                color="info"
                sx={{mr: 15}}
              >
                <Typography variant="button">{messages.showNearMe}</Typography>
              </LoadingButton>
              }
              <Box sx={{display:'flex', alignItems:'center'}}>
                <Typography color="white" variant="body1">{messages.counterLabel(methodPracticesMarkers.length, allOrgIds.length)}</Typography>
              </Box>
            </Stack>
          </Box>
          }
          { showProjectPlanner && 
            <Typography variant="h5">{messages.projectPlannerTitle}</Typography>
          }
        </AppBarHeader>
        <Stack direction="row">
          <Box sx={{height: '100%',  width: !showProjectPlanner ? '100%' : `${Math.round(windowInnerWidth/2)}px`}}>
              <Map
                methodPracticeMarkers={methodPracticesMarkers}
              />
            { !showProjectPlanner && showIntroDialog && 
            <IntroDialog open={introDialogOpen} handleClose={() => setIntroDialogOpen(false)} />
            }
          </Box>
          { showProjectPlanner && 
          <Box sx={{height: '100%',  width: `${Math.round(windowInnerWidth/2)}px`}}>
            <ProjectTools />
          </Box>
          }
        </Stack>
      </Box>
      { !showProjectPlanner && showNewProjectLocation && 
      <SearchLocationModal 
        open={true} 
      />
      }
    </>
  );
};

MapManager.propTypes = {
  showNewProjectButton: PropTypes.bool,
  showIntroDialog: PropTypes.bool,
  halfScreenMap: PropTypes.bool,
};
MapManager.defaultProps = {
  initMethodSearchParams: {
    restorationMethodCategories: [],
    scientificMethodCategories: [],
  },
  showNewProjectButton: false,
  showIntroDialog: true,
  halfScreenMap: false,
};
export default MapManager;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import LogoMarker from '../logoMarker';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// eslint-disable-next-line react/display-name
const Marker = ({ lat, lng, infoCard, logoPath, showInfoCard, onMarkerClick, onInfoCardClose, hoverText, $hover }) => {
  return (
    <>
      <Link lat={lat} lng={lng} onClick={() => onMarkerClick(showInfoCard)}>
        {<LogoMarker logoPath={logoPath} />}
      </Link>
      {showInfoCard &&
        React.cloneElement(infoCard, {
          handleClose: () => onInfoCardClose()
        })}
      {!showInfoCard && hoverText && $hover && 
      <Box sx={{position:'absolute', zIndex:9}}>
        <Typography sx={{backgroundColor: 'white', padding: 0.5}} noWrap variant="caption">{hoverText}</Typography>
      </Box>
      }
    </>
  );
};
//);

Marker.propTypes = {
  // $hover comes from google-maps-react
  $hover: PropTypes.bool,
  hoverText: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  infoCard: PropTypes.element,
  isOrg: PropTypes.bool,
  logoPath: PropTypes.string,
  onInfoCardClose: PropTypes.func,
  onMarkerClick: PropTypes.func,
  showInfoCard: PropTypes.bool,
  toolTipText: PropTypes.string,
};
Marker.defaultProps = {
  toolTipText: '',
  showInfoCard: false,
  isOrg: true
};
export default Marker;

import { atom, selector } from 'recoil';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

export const methodSearchParamState = atom({
  key: 'methodSearchParamState',
  default: {},
});

export const orgSearchParamState = atom({
  key: 'orgSearchParamState',
  default: {},
});

export const locationSearchParamState = atom({
  key: 'locationSearchParamState',
  default: {},
});

export const searchFiltersOpen = atom({
  key: 'searchFiltersOpen',
  default: false,
});

export const methodPracticeParamState = selector({
  key: 'methodPracticeParamState',
  get: ({get}) => {
    const methodSearchParams = get(methodSearchParamState);
    const orgSearchParams = get(orgSearchParamState);
    const locationSearchParams = get(locationSearchParamState);
    
    const {
      restorationMethodTypes,
      restorationMethodSubTypes,
      scientificMethodTypes,
      scientificMethodSubTypes,
      ...methodAttributes
    } = pickBy(
      { ...methodSearchParams, ...orgSearchParams, ...locationSearchParams },
      (value) => !!value
    );

    return ({
      restorationMethodTypes: isEmpty(restorationMethodTypes)
        ? []
        : restorationMethodTypes,
      restorationMethodSubTypes: isEmpty(restorationMethodSubTypes) ? [] : restorationMethodSubTypes,
      scientificMethodTypes: isEmpty(scientificMethodTypes)
        ? []
        : scientificMethodTypes,
      scientificMethodSubTypes: isEmpty(scientificMethodSubTypes) ? [] : scientificMethodSubTypes,
      ...methodAttributes
    });
  },
});
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TopBanner from '../topBanner';
import {APPBAR_BACKGROUND_COLOR} from '../../constants';

const AppBarHeader = ({showNewProjectButton, handleShowNewProject, showVolunteerRegistration, children}) => {
    return (
      <>
      <TopBanner showNewProjectButton={showNewProjectButton} handleShowNewProject={handleShowNewProject} showVolunteerRegistration={showVolunteerRegistration} />
        <AppBar position="static" sx = {{ backgroundColor: APPBAR_BACKGROUND_COLOR }}>
        <Toolbar sx={{marginLeft:2}} disableGutters>
        { children }
        </Toolbar>
    </AppBar>
    </>
    );
  };
  AppBarHeader.propTypes = {
    showNewProjectButton: PropTypes.bool,
    handleShowNewProject: PropTypes.func,
    showVolunteerRegistration: PropTypes.bool,
  }
  AppBarHeader.defaultProps = {
    showNewProjectButton: false,
    handleShowNewProject: () => {},
    showVolunteerRegistration: true,
  }
  export default AppBarHeader;
  
export default {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    email: 'Email',
    countriesAvailable: 'Countries available to work at',
    countries: 'Countries',
    title: 'Volunteer Registration',
    instructions: "Please fill out your information to be added to our Volunteer Database! During a project's planning stages, we will provide registered volunteer information to practioners when a project's location matches any of the countries provided. Volunteers are essential to make restoration projects possible. Thank you!",
    thanks: 'Thank you for your participation!',
    submit: 'Submit',
    submitSuccess: 'Thank you for registering as a volunteer!',
    submitErrorIntro: 'Error registering as a volunteer.',
    submitErrorTryAgain: 'Please try again later.',
    registerAnother: 'Register another volunteer',
};
import axios from 'axios';
import { BASE_URL_API } from '../constants';

export const createVolunteer = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const sm = await axios.post(
    `${BASE_URL_API}/volunteer`,
    params,
    { headers }
  );
  return sm.data;
};

import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

import {
  WORKSITE_NAME_SEARCH_PARAM,
  WORKSITE_LOCATION_TYPE_SEARCH_PARAM,
  locationSearchTypes,
  WORKSITE_COUNTRY_SEARCH_PARAM,
  WORKSITE_REGION_SEARCH_PARAM,
} from '../../constants/location';
import { locationSearchParamState } from '../searchFiltersTabs';


const LocationSearchFilters = ({
  allWorksites,
  allWorksiteCountries,
  allWorksiteRegions,
}) => {
  // const { worksiteName = '', worksiteIds = [], worksiteLocationType = '', worksiteRegion = '', worksiteCountry = '', ...locationSearchParams } = initLocationSearchParams;
  const [locationSearchParams, setLocationSearchParams] = useRecoilState(locationSearchParamState);
  const worksiteLocationType = locationSearchParams[WORKSITE_LOCATION_TYPE_SEARCH_PARAM];
  
  const WorksiteLocationTypeFormControl = () => {
    return (
      <FormControl fullWidth>
        <Select
          value={worksiteLocationType}
          displayEmpty
          labelId="location-type-label"
          id="location-type-select"
          onChange={(e) => {
            setLocationSearchParams(
                { 
                  [WORKSITE_LOCATION_TYPE_SEARCH_PARAM]: e.target.value,
                }
            );
          }}
        >
          {Object.keys(locationSearchTypes).map((locationType, index) => (
            <MenuItem key={index} value={locationType}>{locationSearchTypes[locationType].label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const WorksiteLocationTypeOptionsFormControl = () => {
    const options = getOptionsForLocationType();
    return (
      <FormControl fullWidth>
        <Autocomplete
          value={locationSearchParams[worksiteLocationType]}
          disablePortal
          id="location-type-options-select"
          options={options}
          renderInput={(params) => (
            <TextField {...params} label="" />
          )}
          onChange={(e, value) => {
            setLocationSearchParams(
              lsp => (
                {
                  ...lsp, 
                  [worksiteLocationType]: value.label,
                }
              )
            );
          }}
        />
    </FormControl>
    );
  };

  const getOptionsForLocationType = () => {
    let options = [];
    switch (worksiteLocationType) {
      case WORKSITE_COUNTRY_SEARCH_PARAM:
        options = allWorksiteCountries.map((country) => ({label: country}));
      break;
      case WORKSITE_REGION_SEARCH_PARAM:
        options = allWorksiteRegions.map((region) => ({label: region}));
      break;
      case WORKSITE_NAME_SEARCH_PARAM:
        options = allWorksites.map((worksiteDetails) => {
          return { label: worksiteDetails.name };
        });
      break;
      default:
        // Do nothing
    }
    options.sort((a, b) => {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    return options;
  }

  return (
    <Grid
      container
      rowSpacing={0}
      columnSpacing={1}
      sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 2 }}
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Location</Typography>
      </Grid>
      <Grid item container columnSpacing={1} wrap="nowrap" xs={12}>
        <Grid item xs={6}>
          <WorksiteLocationTypeFormControl />
        </Grid>
        {!!worksiteLocationType && 
          <Grid item xs={6}>
            <WorksiteLocationTypeOptionsFormControl />
          </Grid>
        }
      </Grid>
    </Grid>
  );
};
LocationSearchFilters.propTypes = {
  allWorksites: PropTypes.array.isRequired,
  allWorksiteCountries: PropTypes.array.isRequired,
  allWorksiteRegions: PropTypes.array.isRequired,
};

export default LocationSearchFilters;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Route, Routes, HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { MAP_PAGE, MAP_WITH_SHOW_NEw_PROJECT_BUTTON_PAGE, VOLUNTEER_REGISTRATION_PAGE, PROJECT_PLANNER_PAGE } from './routes';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        <Route path={MAP_PAGE} element={<App showNewProjectButton={false} showInfoDialog />} />
        <Route path={MAP_WITH_SHOW_NEw_PROJECT_BUTTON_PAGE} element={<App showNewProjectButton showInfoDialog={false} />} />
        <Route path={PROJECT_PLANNER_PAGE} element={<App showProjectPlanner={true} />} />
        <Route path={VOLUNTEER_REGISTRATION_PAGE} element={<App isVolunteerRegistration={true} />} />
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppRouter />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
